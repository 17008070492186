<template>
  <div class="locked">
    <div class="pw-box">
      <form @submit.prevent="login">
        <input type="password" v-model="pw" placeholder="Enter Access Code..." />
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';

export default {
  data() {
    return {
      pw: '',
    };
  },
  methods: {
    login() {
      ApiService.post('/config', { password: this.pw }).then((res) => {
        if (res.data.success) {
          this.$store.commit('setPreapproved', true);
          const { from } = this.$route.query;
          this.$router.replace(from || '/');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.locked {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: black;
}
.pw-box {
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  input {
    margin-right: 16px;
  }
}
</style>
